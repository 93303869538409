import React from "react";
import moment from "moment";
import { Link } from "gatsby";
import { Edges } from "../../types";
import styles from "./Feed.module.scss";

type Props = {
  edges: Edges;
};

const Feed = ({ edges }: Props) => (
  <div className={styles["feed"]}>
    {edges.map((edge) => (
      <div
        className={styles["feed__item"]}
        key={edge.node.fields.slug}
        // @ts-ignore
        itemScope
        itemType="http://schema.org/Article"
      >
        <div className={styles["feed__item-meta"]}>
          <time
            className={styles["feed__item-meta-time"]}
            // @ts-ignore
            itemProp="datePublished"
            dateTime={moment(edge.node.frontmatter.date).format("YYYY-MM-DD")}
          >
            {moment(edge.node.frontmatter.date).format("MMMM YYYY")}
          </time>
          <span className={styles["feed__item-meta-divider"]} />
          {/* @ts-ignore */}
          <span
            className={styles["feed__item-meta-category"]}
            itemProp="keywords"
          >
            <Link
              to={edge.node.fields.categorySlug}
              className={styles["feed__item-meta-category-link"]}
            >
              {edge.node.frontmatter.category}
            </Link>
          </span>
        </div>
        {/* @ts-ignore */}
        <h2 className={styles["feed__item-title"]} itemProp="name">
          <Link
            className={styles["feed__item-title-link"]}
            to={edge.node.fields.slug}
          >
            {edge.node.frontmatter.title}
          </Link>
        </h2>
        {/* @ts-ignore */}
        <p className={styles["feed__item-description"]} itemProp="abstract">
          {edge.node.frontmatter.description}
        </p>
        <Link
          className={styles["feed__item-readmore"]}
          to={edge.node.fields.slug}
        >
          Read
        </Link>
      </div>
    ))}
  </div>
);

export default Feed;
